import { gql } from 'graphql-request';

// New - use react-query + react-hook-form
const request = {
	request: {
		name: 'auction>item'
	},

	gql: {
		schema: gql`
			query AuctionItem(
				$real_estate_id: ID,
				$uri_segment: String
			) {
				request(
					real_estate_id: $real_estate_id,
					uri_segment: $uri_segment
				) {
					real_estate_id
					codeid
					transaction_id
					transaction_nm
					real_estate_group_id
					type_id
					type_nm
					subtype_id
					subtype_nm
					geo_location_level1_id
					geo_location_level3_id
					geo_location_level4_id
					location
					address
					postal_code_id
					latitude
					longitude
					codeid
					geodeticid
					geodetic_price
					year_build
					year_adaptation
					description
					auction_sequence_nm
					auction_status_nm
					auction_offer_type_id
					auction_offer_type_nm
					auction_organizer
					price
					price_m2
					price_step
					size
					size_calculated
					is_street_view
					google_street_view_heading
					google_street_view_pitch
					google_street_view_fov
					url_website
					primary_agent_nm
					security_deposit
					security_deposit_due_dt
					auction_source_id
					auction_caseid
					auction_status_id
					auction_location
					auction_share_sale
					auction_start_dt
					auction_start_time
					auction_start_countdown
					auction_start_countdown_days
					auction_valuation_value
					auction_valuation_value_m2
					auction_valuation_dtm
					contact
					specifications
					location
					image {
			      file_system_nm_pub
			      filepath_url
			      filepath_url_preview
			    }
				}
			}
		`
	}
};

export default request;
