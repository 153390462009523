import { gql } from 'graphql-request';

// New - use react-query + react-hook-form
const request = {
	request: {
		name: 'auction>application_mutate'
	},

	form: {
		fieldProps: {
			auction_application_id: {
				required: false
			},

			real_estate_id: {
				required: false
			},

			givenname: {
				required: true
			},

			surname: {
				required: true
			},

			personal_identification_no: {
				required: true
			},

			address: {
				required: true
			},

			postal_code: {
				required: true
			},

			municipality_id: {
				required: true
			},

			country_iso3: {
				required: true
			},

			citizenship: {
				required: true
			},

			email: {
				required: true,
				pattern: /^\S+@\S+$/i
			},

			phone: {
				required: true
			},

			auction_role_id: {
				required: true
			},

			auction_role_behalf_id: {
				required: false
			},

			enforce_preemption_right: {
				required: false
			},

			security_deposit_return_type: {
				required: false
			},

			security_deposit_return_iban: {
				required: false
			},

			security_deposit_return_bank_nm: {
				required: false
			},

			security_deposit_return_bank_bic: {
				required: false
			},

			payment_reference: {
				required: false
			},

			payment_purpose_code: {
				required: false
			},

			payment_purpose: {
				required: false
			},

			is_gdpr_agreement: {
				required: true
			},

			is_tech_agreement: {
				required: true
			}
		}
	},

	gql: {
		schema: gql`
			mutation AuctionApplication(
				$action: String,
				$status_id: Int,
				$auction_application_id: ID,
				$real_estate_id: ID,
				$givenname: String,
				$surname: String,
				$personal_identification_no: String,
				$address: String,
				$postal_code: ID,
				$municipality_id: ID,
				$country_iso3: ID,
				$citizenship: String,
				$email: String,
				$phone: String,
				$auction_role_id: ID,
				$auction_role_behalf_id: ID,
				$enforce_preemption_right: String,
				$security_deposit_return_type: String,
				$security_deposit_return_iban: String,
				$security_deposit_return_bank_nm: String,
				$security_deposit_return_bank_bic: String,
				$payment_reference: String,
				$payment_purpose_code: String,
				$payment_purpose: String,
				$attachment: [AuctionFileInput],
				$is_gdpr_agreement: String,
				$is_tech_agreement: String,
				$note: String
			) {
				request(
					action: $action,
					status_id: $status_id,
					auction_application_id: $auction_application_id,
					real_estate_id: $real_estate_id,
					givenname: $givenname,
					surname: $surname,
					personal_identification_no: $personal_identification_no,
					address: $address,
					postal_code: $postal_code,
					municipality_id: $municipality_id,
					country_iso3: $country_iso3,
					citizenship: $citizenship,
					email: $email,
					phone: $phone,
					auction_role_id: $auction_role_id,
					auction_role_behalf_id: $auction_role_behalf_id,
					enforce_preemption_right: $enforce_preemption_right,
					security_deposit_return_type: $security_deposit_return_type,
					security_deposit_return_iban: $security_deposit_return_iban,
					security_deposit_return_bank_nm: $security_deposit_return_bank_nm,
					security_deposit_return_bank_bic: $security_deposit_return_bank_bic,
					payment_reference: $payment_reference,
					payment_purpose_code: $payment_purpose_code,
					payment_purpose: $payment_purpose,
					attachment: $attachment,
					is_gdpr_agreement: $is_gdpr_agreement,
					is_tech_agreement: $is_tech_agreement,
					note: $note
				) {
					real_estate_id
				}
			}
		`
	}
};

export default request;
